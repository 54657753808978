import React from "react";
import styled from "styled-components";
interface ButtonProps {
  ctaType: string;
  textVal: string;
  className?: string;
}

const Button: React.FC<ButtonProps> = ({ ctaType, textVal, className }) => (
  <Cta className={`${ctaType} ${className}`}>{textVal}</Cta>
);

export default Button;

const Cta = styled.button``;
