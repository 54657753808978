import axios from "axios";

import { FC, ReactElement } from "react";
import { PageMeta } from "components";
import useTranslations from "i18n/useTranslations";
import HeaderGlobal from "components/HeaderGlobal";
import { ROUTE_CONSTANTS, siteUrl } from "constants/routeConstants";
import { Register } from "pages/admin/Register";

const options = {
  method: "GET",
  url: "https://api-nba-v1.p.rapidapi.com/teams",
  headers: {
    "X-RapidAPI-Key": "48b6a42db2mshdc3ba6fed42fc0fp133bb4jsn69c7505f69c2",
    "X-RapidAPI-Host": "api-nba-v1.p.rapidapi.com",
  },
};

export const Admin: FC = (): ReactElement => {
  const { t, tt } = useTranslations();

  return (
    <div>
      <PageMeta
        title={t.pageNames.nba}
        description={tt("adminText")}
        canonicalUrl={siteUrl + ROUTE_CONSTANTS.ADMIN}
      />

      <HeaderGlobal title="Admin" />

      <Register />

      <main style={{ marginTop: "40px" }}></main>
    </div>
  );
};
