import { FC, memo } from "react";
import { Helmet } from "react-helmet-async";

import useTranslations from "i18n/useTranslations";

import favicon1 from "images/favicon-16x16.png";
import favicon2 from "images/favicon-32x32.svg?url";

export interface Props {
  title?: string;
  description?: string;
  image?: string;
  canonicalUrl?: string;
}

const cutTags = (text = ""): string => text.replace(/<\/?.+?>/gi, "");

const prepareData = ({
  title,
  description,
  image,
  canonicalUrl,
}: Props): Props => ({
  title: cutTags(title),
  description: cutTags(description).substr(0, 250),
  image,
  canonicalUrl,
});

const PageMeta: FC<Props> = (props: Props) => {
  const { title, description, image, canonicalUrl } = prepareData(props);
  const { t } = useTranslations();

  return (
    <Helmet>
      {title != null ? <title>{title}</title> : <title>{t.appName}</title>}
      <link rel="icon" type="image/svg+xml" href={favicon2} />
      <link rel="icon" type="image/png" href={favicon1} />
      <meta property="og:title" content={title} />
      <meta property="twitter:title" content={title} />
      <meta name="robots" content="index, follow" />
      {canonicalUrl != null && <link rel="canonical" href={canonicalUrl} />}

      {/*
      Helmet supports only valid head tags, so it is inpossibel even
      to use root tag <></> for inserting several head tags under one condition
      */}
      {description != null && <meta name="description" content={description} />}
      {description != null && (
        <meta property="og:description" content={description} />
      )}
      {description != null && (
        <meta property="twitter:description" content={description} />
      )}
      {image != null && <meta property="og:image" content={image} />}
    </Helmet>
  );
};

const memorizedPageMeta = memo(PageMeta);

export { memorizedPageMeta as PageMeta };
