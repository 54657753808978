import path from "path";

export const ROUTE_CONSTANTS = {
  ABOUT: "/about",
  SKILLS: "/skills",
  CONTACT: "/contact",
  PROJECTS: "/projects",
  HIRE_DEV: "/hire-developer",
  NBA: "/nba-fans",
  //FETCH: '/fetch',
  HOME: "/",
  ADMIN: "/admin",
  SITEMAP: "/sitemap.html",
  NOT_FOUND: "/404",
};
//export const siteURL = `${window.location.protocol}//${window.location.hostname}`;
export const siteUrl = "https://rafalwilinski.com";
export const siteUrlwww = "https://www.rafalwilinski.com";
