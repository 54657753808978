import axios from "axios";

import { FC, ReactElement, useEffect, useState } from "react";
import styles from "./nba.module.scss";
import { PageMeta } from "components";
import useTranslations from "i18n/useTranslations";
import HeaderGlobal from "components/HeaderGlobal";
import cn from "classnames";
import { ROUTE_CONSTANTS, siteUrl } from "constants/routeConstants";
import { NBATeamItem } from "pages/nba/NBATeamItem";

const options = {
  method: "GET",
  url: "https://api-nba-v1.p.rapidapi.com/teams",
  headers: {
    "X-RapidAPI-Key": "48b6a42db2mshdc3ba6fed42fc0fp133bb4jsn69c7505f69c2",
    "X-RapidAPI-Host": "api-nba-v1.p.rapidapi.com",
  },
};
const TodayDate = new Date().toISOString().split("T")[0];

const NBA_options = {
  method: "GET",
  url: "https://api-nba-v1.p.rapidapi.com/games",
  params: { date: TodayDate },
  headers: {
    "X-RapidAPI-Key": "48b6a42db2mshdc3ba6fed42fc0fp133bb4jsn69c7505f69c2",
    "X-RapidAPI-Host": "api-nba-v1.p.rapidapi.com",
  },
};
const Spinner = () => {
  return (
    <div className="text-center">
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};
const getTodayGames = async () => {
  try {
    const response = await axios.request(NBA_options);
    return response.data.response;
  } catch (error) {
    console.error(error);
  }
};

interface IBadge {
  type: string;
}
const ResultBadge = (props: IBadge) => (
  <span className={`badgeScore badge-${props.type}`}>{props.type}</span>
);

export const Nba: FC = (): ReactElement => {
  const { t, tt } = useTranslations();
  const [teamsData, setTeamsData] = useState<any>(null);
  const [games, setGames] = useState<any>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.request(options);

        const teams = response.data.response
          .map((team: any) => {
            if (
              team.leagues.standard &&
              team.nbaFranchise &&
              team.leagues.standard.conference !== "Intl" &&
              team.name !== "Home Team Stephen A"
            ) {
              return team;
            }
            return null;
          })
          .filter((team: any) => team !== null);

        setTeamsData(teams);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();

    getTodayGames().then((todayGames) => setGames(todayGames));

    return () => {};
  }, []);

  return (
    <div className={cn(styles.mainNba)}>
      <PageMeta
        title={t.pageNames.nba}
        description={tt("nbaText")}
        canonicalUrl={siteUrl + ROUTE_CONSTANTS.NBA}
      />

      <HeaderGlobal title="Nba fans" />

      <main style={{ marginTop: "40px" }}>
        <h3 className={`mt-5 ${styles.heading}`}>Today's games</h3>

        {games ? (
          <div className="container-fluid mb-5">
            <div className="row">
              {games.map((game: any) => {
                const isHomeWin =
                  game.scores.home.points > game.scores.visitors.points;
                const isDraw =
                  game.scores.home.points === game.scores.visitors.points;
                const gameHomeResult = isDraw
                  ? "Draw"
                  : isHomeWin
                  ? "Win"
                  : "Lose";
                const gameVisitorsResult = isDraw
                  ? "Draw"
                  : !isHomeWin
                  ? "Win"
                  : "Lose";
                return (
                  <div key={game.id} className={`col-12 col-md-6`}>
                    <div className={styles.gameContainer}>
                      <div className="col-12 text-center">
                        <h3 className={styles.scoreHeading}>SCORE:</h3>
                        <div className={styles.score}>
                          <span
                            className={
                              isDraw
                                ? styles.colorDraw
                                : isHomeWin
                                ? styles.colorWin
                                : styles.colorLose
                            }
                          >
                            <ResultBadge type={gameHomeResult} />
                            {game.scores.home.points}
                          </span>
                          :
                          <span
                            className={
                              isDraw
                                ? styles.colorDraw
                                : !isHomeWin
                                ? styles.colorWin
                                : styles.colorLose
                            }
                          >
                            {game.scores.visitors.points}
                            <ResultBadge type={gameVisitorsResult} />
                          </span>
                        </div>
                      </div>
                      <div className="col-12 d-flex flex-row text-center ">
                        <div className={styles.game}>
                          <p className="m-0 pb-3">Home</p>
                          <img
                            width="80"
                            height="80"
                            src={game.teams.home.logo}
                            loading="lazy"
                          />
                        </div>
                        <div className={styles.game}>
                          <p className="m-0 pb-3">Visitors</p>
                          <img
                            width="80"
                            height="80"
                            src={game.teams.visitors.logo}
                            loading="lazy"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <Spinner />
        )}

        <h3 className={`mb-5 ${styles.heading}`}>NBA Teams</h3>
        {teamsData ? (
          <>
            <ul className={`mt-5 ${styles.nbaTeamList}`}>
              {teamsData.map((team: any, index: number) => (
                <NBATeamItem key={index} team={team} index={index} />
              ))}
            </ul>
          </>
        ) : (
          <Spinner />
        )}
      </main>
    </div>
  );
};
