import { Formik, FormikHelpers } from "formik";
import classes from "pages/contact/contact.module.css";
import { customApi } from "api";
import { RegisterApi, useRegisterUserMutation } from "api/custom/customApi";

interface RegValues {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
}

export const Register = () => {
  const [registerUser, { isLoading, isError }] = useRegisterUserMutation();

  return (
    <div className="my-5">
      <h2>Register</h2>

      <Formik
        initialValues={{
          email: "",
          firstName: "",
          lastName: "",
          password: "",
        }}
        validate={(values) => {
          const errors: any = {};
          if (!values.email) {
            errors.email = (
              <p style={{ color: "red", margin: "0 0 10px 0" }}>Required </p>
            );
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = (
              <p style={{ color: "red", margin: "0 0 10px 0" }}>
                Invalid email address{" "}
              </p>
            );
          }
          if (values.firstName.length < 2) {
            errors.firstName = (
              <p style={{ color: "red", margin: "0 0 10px 0" }}>Too Short </p>
            );
          } else if (values.firstName.length > 50) {
            errors.firstName = (
              <p style={{ color: "red", margin: "0 0 10px 0" }}>Too Long </p>
            );
          }

          if (values.lastName.length < 2) {
            errors.lastName = (
              <p style={{ color: "red", margin: "0 0 10px 0" }}>Too Short </p>
            );
          } else if (values.lastName.length > 50) {
            errors.lastName = (
              <p style={{ color: "red", margin: "0 0 10px 0" }}>Too Long </p>
            );
          }

          if (values.password.length > 15) {
            errors.password = (
              <p style={{ color: "red", margin: "0 0 10px 0" }}>Too Long </p>
            );
          }
          return errors;
        }}
        onSubmit={async (
          values: RegValues,
          { setSubmitting }: FormikHelpers<RegValues>
        ) => {
          // setTimeout(() => {
          //   alert(JSON.stringify(values, null, 2));
          //   setSubmitting(false);
          //   RegisterApi.endpoints.registerUser.
          // }, 500);

          try {
            const result = await registerUser(values);
            // Handle success (result.data) if needed
            console.log("results", result);
          } catch (error) {
            console.log("err", error);
            // Handle error (error.message) if needed
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          isValidating,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit} className={classes.formStyles}>
            <input
              type="text"
              name="firstName"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.firstName}
              placeholder="First Name"
              className={classes.inputStyle}
            />
            {errors.firstName && touched.firstName && errors.firstName}
            <input
              type="text"
              name="lastName"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.lastName}
              placeholder="Last Name"
              className={classes.inputStyle}
            />
            {errors.lastName && touched.lastName && errors.lastName}
            <input
              type="email"
              name="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              placeholder="Email address"
              className={classes.inputStyle}
            />
            {errors.email && touched.email && errors.email}

            <input
              type="password"
              name="password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              placeholder="Password"
              className={classes.inputStyle}
            />
            {errors.password && touched.password && errors.password}

            <input
              type="submit"
              className={classes.borderedSubmit}
              value="Send"
            />
          </form>
        )}
      </Formik>
    </div>
  );
};
