import { FC, ReactElement } from "react";

import { PageMeta } from "components";
import useTranslations from "i18n/useTranslations";
import styles from "./hiredev.module.scss";
import HeaderGlobal from "components/HeaderGlobal";
import cn from "classnames";
import { ROUTE_CONSTANTS, siteUrl } from "constants/routeConstants";
import styled from "styled-components";
import FullTimeImage from "images/calendar-clock.svg";
import PartTimeImage from "images/duration.svg";
import DemandImage from "images/clock-ten.svg";
import ContractImage from "images/file-signature.svg";
import { Link } from "react-router-dom";
import Button from "components/CTA/Button";
import ctaStyle from "components/CTA/cta.module.css";

const pricingTableData = [
  {
    id: 1,
    title: "Full time",
    src: <FullTimeImage />,
    workingTime: ["20 Days/Month", "8 Hours/Day"],
    billing: "Monthly",
    workTerm: "Minimum 3 Months",
  },
  {
    id: 2,
    title: "Part time",
    src: <PartTimeImage />,
    workingTime: ["8-10 Days/Month", "8 Hours/Day"],
    billing: "Monthly",
    workTerm: "Minimum 1 Months",
  },
  {
    id: 3,
    title: "On demand",
    src: <DemandImage />,
    workingTime: ["Minimum 1 Hour/Day", ""],
    billing: "Hourly (per the rates)",
    workTerm: "Block of 45 hours purchased in advance",
  },
  {
    id: 4,
    title: "Contract or Project",
    src: <ContractImage />,
    workingTime: ["20 Days/Month", "8 Hours/Day"],
    billing: "Monthly",
    workTerm: "To discus",
  },
];

interface IPriceItem {
  priceItem: {
    id: number;
    title: string;
    src: string | ReactElement;
    workingTime: string[];
    billing: string;
    workTerm: string;
  };
}

const PriceItem = (props: IPriceItem) => {
  const { id, title, src, workingTime, billing, workTerm } = props.priceItem;
  return (
    <li className={styles.priceItem} id={`price_${id}`}>
      <div className={styles.circleImgWrapper}>{src}</div>
      <h3>{title}</h3>
      <div className={styles.priceItemInfo}>
        <span className={styles.label}>Hire model</span>
        <ul className={styles.workingTime}>
          {workingTime.map((time) => (
            <li>{time}</li>
          ))}
        </ul>
        <span className={styles.label}>Billing</span>
        <p>{billing}</p>

        <span className={styles.label}>Terms</span>
        <p>{workTerm}</p>
      </div>
    </li>
  );
};

export const HireDev: FC = (): ReactElement => {
  const { t, tt } = useTranslations();

  return (
    <div className={cn("mainHireDev", styles.hiredev)}>
      <PageMeta
        title={t.pageNames.hiredev}
        description={tt("hireDevText")}
        canonicalUrl={siteUrl + ROUTE_CONSTANTS.HIRE_DEV}
      />

      <HeaderGlobal title="Hire developer" />

      <main style={{ marginTop: "40px" }}>
        <div>
          <p className={styles.topParagraph}>
            Hiring a dedicated developer or team becomes imperative in various
            scenarios. One common situation is when there's a lack of specific
            in-house software development expertise, and bringing in external
            professionals proves beneficial to fill these skill gaps
            effectively. Additionally, if the search for suitable local
            developers becomes time-sensitive, opting for a dedicated team
            offers a prompt solution to meet project deadlines.
          </p>
          <p className={styles.topParagraph}>
            The flexibility of a dedicated model also proves useful when a
            business needs extra software developers to support its growth
            without committing to permanent hires. Furthermore, choosing a
            dedicated development team can be a cost-effective strategy for
            minimizing internal operational costs and saving money.
          </p>
        </div>

        <PricingContainer className="mt-5">
          <PricingList>
            {pricingTableData.map((item) => {
              return <PriceItem key={item.id} priceItem={item} />;
            })}
          </PricingList>
          {/*<PricingDetails className={styles.priceDesc}>details</PricingDetails>*/}
        </PricingContainer>

        <div className="d-flex flex-row justify-content-center my-5">
          <Link className="no-style" to={ROUTE_CONSTANTS.CONTACT}>
            <Button
              ctaType={ctaStyle.borderedCTA}
              className={`${ctaStyle.fixed_width} ${ctaStyle.center}`}
              textVal="Contact us"
            />
          </Link>
        </div>
      </main>
    </div>
  );
};

const PricingList = styled.ul`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 20px 10px 20px 0px;
  grid-column-gap: 10px;
  flex: 2;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 560px) {
    grid-template-columns: 1fr;
  }
`;
const PricingDetails = styled.div`
  display: block;
  flex: 1;
`;

const PricingContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
