import NavDetails from "../Nav/NavDetails";
import { Logo } from "./Logo/Logo";
interface IProps {
  handleMobNav: () => void;
}
export const Nav = (props: IProps) => {
  return (
    <>
      <Logo />
      <NavDetails handleMobNav={props.handleMobNav} />
      <div></div>
    </>
  );
};
