import React from "react";
import styled from "styled-components";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// import { library } from '@fortawesome/fontawesome-svg-core';
// import { fab } from '@fortawesome/free-brands-svg-icons';
// import { faPhoneAlt, faEnvelopeOpen, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import classes from "./contact.module.css";
import pl from "../../assets/pl.png";
import uk from "../../assets/uk.png";
// library.add(fab, faPhoneAlt, );

const ContactDetailsStyled = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
`;

const AnchorStyled = styled.a`
  margin-bottom: 7px;
  color: #a4acc4;
  text-decoration: none;
  font-size: 14px;
`;

const Flag = styled.img`
  width: 21px;
  height: 13px;
  margin: 0px 5px 0px 0px;
`;

const ContactDetails = () => {
  return (
    <ContactDetailsStyled>
      <AnchorStyled href="tel: +44 7397 853187 ">
        {/*<FontAwesomeIcon*/}
        {/*  icon={faPhoneAlt}*/}
        {/*  color="rgb(3, 127, 255)"*/}
        {/*  className={classes.contactIcons}*/}
        {/*/>{" "}*/}
        <Flag src={uk} />
        +44 7397 853187
      </AnchorStyled>
      <AnchorStyled href="mailto:rafal.wili.uk@gmail.com">
        {/*<FontAwesomeIcon*/}
        {/*  icon={faEnvelopeOpen}*/}
        {/*  color="rgb(3, 127, 255)"*/}
        {/*  className={classes.contactIcons}*/}
        {/*/>*/}
        rafal.wili.uk@gmail.com
      </AnchorStyled>
      <AnchorStyled href="/contact">
        {/*<FontAwesomeIcon*/}
        {/*  icon={faMapMarkerAlt}*/}
        {/*  color="rgb(3, 127, 255)"*/}
        {/*  className={classes.contactIcons}*/}
        {/*/>{" "}*/}
        United Kingdom <br /> 287 Alsham drive UB10 8Uj Ickencham
      </AnchorStyled>
      <p></p>

      <AnchorStyled style={{ marginTop: "40px" }} href="tel:+48 605 189 997">
        {/*<FontAwesomeIcon*/}
        {/*  icon={faPhoneAlt}*/}
        {/*  color="rgb(3, 127, 255)"*/}
        {/*  className={classes.contactIcons}*/}
        {/*/>{" "}*/}
        <Flag src={pl} /> +48 605 189 997
      </AnchorStyled>

      <AnchorStyled href="mailto:rafal.wili.uk@gmail.com">
        {/*<FontAwesomeIcon*/}
        {/*  icon={faEnvelopeOpen}*/}
        {/*  color="rgb(3, 127, 255)"*/}
        {/*  className={classes.contactIcons}*/}
        {/*/>*/}
        rafal.wili.uk@gmail.com
      </AnchorStyled>

      <AnchorStyled style={{ marginBottom: "40px" }} href="/contact">
        {/*<FontAwesomeIcon*/}
        {/*  icon={faMapMarkerAlt}*/}
        {/*  color="rgb(3, 127, 255)"*/}
        {/*  className={classes.contactIcons}*/}
        {/*/>{" "}*/}
        Poland Trypucie 18-106 <br /> Woj. Podlaskie
      </AnchorStyled>
    </ContactDetailsStyled>
  );
};

export default ContactDetails;
