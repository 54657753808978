import logo from "../../../assets/logo.png";

const styles = {
  imgContainer: { width: "100%", display: "block", height: "auto" },
  imgStyle: {
    width: "50%",
    height: "auto",
    margin: "0 auto",
    display: "block",
  },
};

export const Logo = () => {
  return (
    <div style={styles.imgContainer}>
      <img src={logo} alt="Logo" style={styles.imgStyle} />
    </div>
  );
};
