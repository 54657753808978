import styled from "styled-components";

export const NBATeamItem = (props: any) => {
  const { team, index } = props;
  return (
    <TeamLi key={team.id} className={`team_nr_${index + 1}`}>
      <img width="80" height="80" src={team.logo} loading="lazy" />
      <p>{team.name}</p>
    </TeamLi>
  );
};

const TeamLi = styled.li`
  border: 1px solid gray;
  list-style: none;
  padding: 20px 6px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  text-align: center;
  background: #696969;
  img {
    margin-bottom: 1rem;
  }
`;
