import { FC, ReactElement } from "react";

import { PageMeta } from "components";
import useTranslations from "i18n/useTranslations";
import { ROUTE_CONSTANTS, siteUrl } from "constants/routeConstants";

const Sitemap: FC = (): ReactElement => {
  const { t } = useTranslations();

  return (
    <div className="main sitemap_page">
      <PageMeta
        title={t.pageNames.sitemap}
        canonicalUrl={siteUrl + ROUTE_CONSTANTS.SITEMAP}
      />
      <h1>{t.sitemapText}</h1>

      <ul>
        {Object.entries(ROUTE_CONSTANTS).map(([key, value]) => (
          <li key={key}>
            <a href={value}>
              {key.charAt(0).toUpperCase() + key.slice(1).toLowerCase()}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export { Sitemap };
