import { FC, ReactElement } from "react";
import { Link } from "react-router-dom";
import Button from "components/CTA/Button";
import ctaStyle from "components/CTA/cta.module.css";

import homeBanner from "../../assets/homeBanner.png";
//import { useAppSelector, useAppDispatch } from 'store/store'
import useTranslations from "i18n/useTranslations";

import { PageMeta } from "components";

import classes from "./home.module.scss";
import { ROUTE_CONSTANTS, siteUrl } from "constants/routeConstants";

const Home: FC = (): ReactElement => {
  const { t, tt } = useTranslations();
  // const dispatch = useAppDispatch()

  return (
    <div className="mainHome home">
      <PageMeta
        title={t.pageNames.home}
        description={tt("homeText")}
        canonicalUrl={siteUrl + ROUTE_CONSTANTS.HOME}
      />
      <div className={classes.homePageWrapper}>
        <header className={classes.header}>
          <div className={classes.homeHeader}>
            <h1 className={classes.homeHeaderH1}>
              Hi, <br />
              I'm <span className={classes.textBlue}>Rafal </span> <br />
              Web Developer .
            </h1>
            <span className={classes.homeHeaderSubtext}>
              <span className={classes.homeHeaderSpec}>
                Front End Developer
              </span>{" "}
              /
              <span className={classes.homeHeaderSpec}>
                {" "}
                WordPress Specialist{" "}
              </span>{" "}
              /<span className={classes.homeHeaderSpec}> Freelancer </span>
            </span>

            <Link to="/contact">
              <Button ctaType={ctaStyle.borderedCTA} textVal="Contact me" />
            </Link>
          </div>
          <div className={classes.homeHeaderBannerWrapper}>
            <img
              className={classes.homeHeaderBannerImg}
              src={homeBanner}
              alt="homeBanner1"
            />
          </div>
        </header>
      </div>
    </div>
  );
};

export { Home };
