import { FC, ReactElement } from "react";

import { PageMeta } from "components";
import useTranslations from "i18n/useTranslations";

import styles from "./skills.module.scss";
import cn from "classnames";
import { SkillItem } from "pages/skills/SkillItem";
import styled from "styled-components";
import { ROUTE_CONSTANTS, siteUrl } from "constants/routeConstants";
import HeaderGlobal from "components/HeaderGlobal";

const skillsList = [
  {
    id: 1,
    title: "HTML / CSS Skills",
    content:
      "As an experienced web developer specializing in HTML and CSS, my skills enable me to create modern and functional websites, with a keen focus on the semantic structure of the code and the aesthetics of the design. I craft responsive, visually appealing projects, utilizing flexbox and grid layout techniques. Additionally, I implement subtle animations to enhance the overall attractiveness of the site.",
  },
  {
    id: 2,
    title: "JavaScript ES6+ / JQuery",
    content:
      "I adhere to the latest JavaScript language standards, allowing me to efficiently create modern solutions. I am familiar with and utilize features such as arrow functions, destructuring, promises, async/await, etc. Leveraging my skills in jQuery, I develop responsive and dynamic interfaces, streamlining programming processes while maintaining consistency in handling events and manipulating the DOM",
  },
  {
    id: 3,
    title: "React / TypeScript /Redux",
    content:
      "React enables me to design user interfaces using components, making applications scalable and easy to maintain. I use TypeScript to enhance code safety and facilitate teamwork. I am proficient in defining types, interfaces, and understanding the benefits of strong typing. With Redux, I ensure effective state management for applications. I design data structures, actions, and reducers, creating transparent and scalable architectures.",
  },
  {
    id: 4,
    title: "SEO Skills",
    content:
      "Optimization for search engines (SEO) is a crucial component of a marketing strategy that can significantly impact a company's visibility in online search results. A company that effectively leverages SEO strategy can enhance its online visibility, reach a broader audience of potential customers, and establish lasting relationships with online users. A properly optimized website is a key element of an effective marketing strategy.",
  },
  {
    id: 5,
    title: "Testing Skills",
    content:
      "Testing is an integral part of the product or software development process. My company, diligently conducting tests, is able to identify potential errors, optimize functionalities, and deliver high-quality products to users. Through systematic testing, the company can build trust in its products among customers, avoid issues related to performance, and continually refine its offerings.",
  },
  {
    id: 6,
    title: "Debugging Skills",
    content:
      "Debugging skills are crucial for effectively identifying, analyzing, and fixing errors in source code. I possess strong debugging skills, enabling me to diagnose issues effectively, contributing to faster and more efficient software development.",
  },
  {
    id: 7,
    title: "Hosting",
    content:
      "Elevate your website with confidence and speed by choosing our hosting services! With us, your site will not only gain a robust foundation but will also thrive with dynamic hosting. Our platform provides not just space for your website, but also ensures seamless operation. By choosing us, you're not just getting hosting; you're unlocking the doors to unlimited online potential!",
  },
  {
    id: 8,
    title: "Code Versioning",
    content:
      "In our projects, we leverage advanced code versioning tools to ensure smooth and coordinated development. Proficiency in using version control systems is a crucial aspect of our projects. Through these tools, we not only track the evolution of code but also effectively manage changes, collaborate seamlessly as a team, and maintain consistency across different project versions. Mastering version control techniques is not just a standard for us; it's a way to uphold order and efficiency in the software development process.",
  },
  {
    id: 9,
    title: "Understanding Clients",
    content:
      "Understanding the client's needs is not just a characteristic of ours, it's our strength. In a client project, we go beyond delivering solutions; we meticulously analyze their challenges and business goals. Our ability to understand the client translates into providing personalized solutions that not only meet expectations but also deliver tangible benefits. We don't limit ourselves to understanding technical specifications – we aim to comprehend the entire concept and business context, enabling us to create innovative projects tailored to real needs",
  },
  {
    id: 10,
    title: "Agencies Experience",
    content:
      "Our experience working with various agencies has enriched our perspective and refined our approach to collaborative projects. Navigating diverse agency environments has equipped us with adaptability and a deep understanding of different workflows and client expectations. Drawing from this wealth of experience, we seamlessly integrate into agency dynamics, fostering effective communication and delivering solutions that align with the unique goals and challenges each agency faces. Our agency experience is not just a testament to our versatility, but also a commitment to enhancing the collaborative landscape in every project we undertake.",
  },
  {
    id: 11,
    title: "Wordpress",
    content:
      "Our extensive experience in WordPress eCommerce allows us to deliver comprehensive and effective solutions for online stores. Proficiency in the WordPress platform and its eCommerce extensions enables us not only to create visually appealing and user-friendly stores but also to optimize the purchasing processes for customers.\n" +
      "\n" +
      "With our experience in WordPress eCommerce, we can tailor stores to meet the unique needs of clients, integrate various online payment methods, optimize websites for search engines, and provide functionalities that enhance conversion rates and customer satisfaction. Regardless of the complexity of the project challenges, our expertise in WordPress eCommerce enables us to effectively meet client expectations and ensure a robust and innovative online presence for their business.",
  },
  {
    id: 12,
    title: "PHP / Backend",
    content:
      "PHP is the backbone of our backend operations, enabling us to create dynamic, scalable, and efficient web applications. Our deep understanding of PHP and its frameworks allows us not only to effectively manage databases but also to integrate various features, leading to smooth interaction between the frontend and backend.\n" +
      "\n" +
      "In the backend realm, we not only deliver robust and secure solutions but also continually update our knowledge to stay current with the latest trends and technologies. Our approach to PHP in the backend is based on flexibility, allowing us to tailor our projects to the individual needs of clients, regardless of scale or industry specifics. With PHP as the backend language, you can be confident that your applications are not only efficient but also robust and future-ready.",
  },
];

export const Skills: FC = (): ReactElement => {
  const { t, tt } = useTranslations();

  return (
    <div className={cn("mainSkills Skills", styles.skills)}>
      <PageMeta
        title={t.pageNames.skills}
        description={tt("skillsText")}
        canonicalUrl={siteUrl + ROUTE_CONSTANTS.SKILLS}
      />
      <HeaderGlobal title={"Skills"} />

      <Section className="mt-5">
        {skillsList.map((skill, index) => (
          <SkillCol key={skill.id} className={styles.skillColumn}>
            <SkillItem
              id={skill.id}
              title={skill.title}
              content={skill.content}
            />
          </SkillCol>
        ))}
      </Section>
    </div>
  );
};

const SkillCol = styled.div``;
const Section = styled.section`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 25px;
  margin-bottom: 40px;

  &.reverse {
    div {
      &:nth-of-type(odd) {
        order: 1;
      }

      &:nth-of-type(even) {
        order: -1;
      }
    }
  }
  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 1100px) {
    grid-template-columns: repeat(1, 1fr);

    &.reverse {
      div {
        &:nth-of-type(odd) {
          order: 1;
        }

        &:nth-of-type(even) {
          order: 2;
        }
      }
    }
  }
`;
