import { FC, ReactElement } from "react";

import { PageMeta } from "components";
import useTranslations from "i18n/useTranslations";
import styles from "./about.module.scss";
import HeaderGlobal from "components/HeaderGlobal";
import HalfWidthTextAndBtn from "components/HalfWidthTextAndBtn";
import aboutImg from "../../assets/unsplash2.jpg";
import cn from "classnames";
import { ROUTE_CONSTANTS, siteUrl } from "constants/routeConstants";
import { Link } from "react-router-dom";
import Button from "components/CTA/Button";
import ctaStyle from "components/CTA/cta.module.css";

type PageContent = {
  title: string;
  mainContent: string;
  btnURL?: string;
  btnText?: string;
};

type IconData = {
  icon: string;
  title: string;
  ContentText: string;
};

type ContentTextObject = {
  page: {
    Home: {};
    About: PageContent[];
    Skils: PageContent;
    Contact: {};
  };
  iconsData: IconData[];
};

export const ContentText = (): ContentTextObject => {
  return {
    page: {
      Home: {},
      About: [
        {
          title: "Developing websites/web apps with modern tech.",
          mainContent:
            "I'll take care of the image of your company, and will take care of your brand or product closely. Each site is based on the latest trends in web development. The acquired experience allows me to build the highest quality site for our customers based on a thorough analysis of the needs and competition of all customers, this maximising the effectiveness of sites. As a result, such a prepared and designed website reaches specific recipients, which in effect distinguishes your company from the competition.",
          btnURL: "contact",
          btnText: "Contact me",
        },
        {
          title: "Building Bespoke Functionalities you need.",
          mainContent:
            "Bespoke software developed specifically for customer needs. Why’ to use the not off-the-shelf solutions? There are many reasons for this. First, custom tools are anxiously developed to meet your requirements and needs. Developed custom, it has no useless features which you can face with, having commonly used applications. In terms of your business evolution and expanding — the bespoke software is the part that evolves as well. This kind of program is flexible to the product features and details. And that is the reason why companies of different branches, from private to government, are using the tailor-made programs.",
          btnURL: "skills",
          btnText: "See What We Do",
        },
      ],
      Skils: {
        title: "Fully Resposnsive",
        mainContent:
          "lmost every new client these days wants a mobile version of their website, that why we are here for you.",
        btnURL: "about#what_we_do",
        btnText: "See What We Do",
      },
      Contact: {},
    },
    iconsData: [
      {
        icon: "faChartBar",
        title: "Positioning",
        ContentText:
          "The latest and most effective positioning methods that will ensure high search rates in the most popular search engines. Meet the expectations of the company and hit the specific audience.",
      },
      {
        icon: "faCode",
        title: "Semantics",
        ContentText:
          "The website prepared by us is made according to the programming standards, thanks to such operation and the use of special semantics the website will be very well appreciated by search robots which uses eg Google.",
      },
      {
        icon: "faDatabase",
        title: "Hosting",
        ContentText:
          "Free Hosting for the First Year! Our servers are safe and fault-free. Reliability, reliability and stability are their main characteristics.",
      },
      {
        icon: "faFirefoxBrowser",
        title: "Browser",
        ContentText:
          "We provide compatibility of all browsers Mozilla Firefox, Google Chrome, Safari, Microsoft Internet Explorer. Now every customer will see your website correctly.",
      },
    ],
  };
};

// Loadable component or page should not be in index.ts file of components or page folder
// const LoadableComponent = loadable(
//   async () =>
//     await import(
//       /* webpackChunkName: "loadable-component" */ 'components/loadable-component/LoadableComponent'
//     ),
//   {
//     resolveComponent: (components) => components.LoadableComponent,
//     fallback: <Spinner />
//   }
// )

export const About: FC = (): ReactElement => {
  const { t, tt } = useTranslations();

  return (
    <div className={cn("mainAbout", styles.about)}>
      <PageMeta
        title={t.pageNames.about}
        description={tt("aboutText")}
        canonicalUrl={siteUrl + ROUTE_CONSTANTS.ABOUT}
      />

      <HeaderGlobal title="About me" />

      <main style={{ marginTop: "40px" }}>
        <div className="d-flex flex-column flex-lg-row justify-content-between">
          <HalfWidthTextAndBtn
            title={ContentText().page.About[0].title}
            mainContent={ContentText().page.About[0].mainContent}
            btnText={ContentText().page.About[0].btnText!!}
            btnURL={ContentText().page.About[0].btnURL!!}
            txtColor="white"
          />

          <div className="w-45 mr-4">
            <img className="imageClass " src={aboutImg} alt="placeholder" />
          </div>
        </div>

        <div className="d-flex  flex-column flex-lg-row  justify-content-center my-5">
          <Link to={ROUTE_CONSTANTS.HIRE_DEV}>
            <Button ctaType={ctaStyle.borderedCTA} textVal="Hire developer" />
          </Link>
        </div>

        <div className="my-5" id="what_we_do"></div>

        <div className="d-flex flex-column flex-lg-row justify-content-between my-5">
          <div className="w-45 mr-4">
            <img className="imageClass " src={aboutImg} alt="placeholder" />
          </div>

          <HalfWidthTextAndBtn
            title={ContentText().page.About[1].title}
            mainContent={ContentText().page.About[1].mainContent}
            btnText={ContentText().page.About[1].btnText!!}
            btnURL={ContentText().page.About[1].btnURL!!}
            txtColor="white"
            h2spacing
          />
        </div>
      </main>
    </div>
  );
};
