import { FC, ReactElement, useState } from "react";

import { PageMeta } from "components";
import useTranslations from "i18n/useTranslations";
import styled from "styled-components";
import ContactDetails from "./ContactDetails";
import contactUs from "../../assets/contact-us.png";
import { InfoPopUp } from "components/Global/InfoPopUp";
import { ContactUsForm } from "pages/contact/ContactUsForm";
import HeaderGlobal from "components/HeaderGlobal";
import { ROUTE_CONSTANTS, siteUrl } from "constants/routeConstants";

const ContactPageHolder = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 992px) {
    flex-direction: column;
  }
`;

const LeftContact = styled.div`
  width: 40%;
  padding: 15px 45px 15px 0;
  box-shadow: 25px 0 20px -7px #2f3148;
  height: 100vh;
  box-sizing: border-box;

  @media (max-width: 992px) {
    width: 100%;
    height: inherit;
    margin-bottom: 40px;
    box-shadow: 0 0 20px -7px #2f3148;
  }
`;
const RightContact = styled.div`
  width: 60%;
  padding: 15px 15px 15px 45px;
  position: relative;
  overflow: hidden;

  @media (max-width: 992px) {
    width: 100%;
    padding: 15px 15px 15px 5px;
  }
`;
const StyledImageWrapper = styled.div`
  width: 70%;
  height: auto;
  position: absolute;
  right: 0px;
  bottom: 0px;
  z-index: 10;

  @media (max-width: 992px) {
    width: 100%;
    height: auto;
    position: relative;
    right: unset;
    bottom: unset;
    z-index: unset;
    margin: 35px auto 0;
  }

  & > img {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
  }
`;

export const Contact: FC = (): ReactElement => {
  const { t, tt } = useTranslations();

  const [isFormSubmited, setIsFormSubmited] = useState(false);
  const [formSubmitionStatus, setFormSubmitionStatus] = useState(false);

  const isFormSubmitedHandler = (formSubmitionStatus: boolean) => {
    setIsFormSubmited(!isFormSubmited);
    setFormSubmitionStatus(formSubmitionStatus);
  };

  return (
    <div className="mainContact contact">
      <PageMeta
        title={t.contactText}
        canonicalUrl={siteUrl + ROUTE_CONSTANTS.CONTACT}
      />

      <ContactPageHolder>
        <LeftContact>
          <ContactUsForm
            isFormSent={isFormSubmited}
            FormSendingCheck={(formSubmitionStatus) =>
              isFormSubmitedHandler(formSubmitionStatus)
            }
          />
        </LeftContact>

        <RightContact>
          <HeaderGlobal title={tt("contactText")} />
          <div>
            <ContactDetails />
          </div>
          <StyledImageWrapper>
            <img src={contactUs} alt="contact us" />
          </StyledImageWrapper>
          {isFormSubmited && formSubmitionStatus ? (
            <InfoPopUp
              content="Message sent. We will get in touch with you soon. "
              infotype={formSubmitionStatus ? "alertSuccess" : "alertError"}
            />
          ) : isFormSubmited && !formSubmitionStatus ? (
            <InfoPopUp
              content="Something went wrong please try again. "
              infotype={formSubmitionStatus ? "alertSuccess" : "alertError"}
            />
          ) : null}
        </RightContact>
      </ContactPageHolder>
    </div>
  );
};
