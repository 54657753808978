import { useEffect, useState } from "react";
import { useRoutes, useLocation } from "react-router-dom";

import { routes } from "router/Router";
import { useAppDispatch } from "store/store";
import { switchToDark } from "store/theme/themeSlice";
import { Nav } from "components/Nav/Nav";
import { MobileNav } from "components/Nav/MobileNav";
import { Offline } from "components/offline/Offline";
//import { THEME_NAMES } from "constants/commonConstants";
import isMobile from "is-mobile";

export const App = () => {
  const content = useRoutes(routes);
  //const currentTheme = useAppSelector((state) => state.theme.theme);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { hash, pathname, search } = location;

  const [currentPage, setCurrentPage] = useState("home");
  const [mainBG, setMainBG] = useState("#00001c");
  const [mobileNavActive, setMobileNavActive] = useState(false);

  const handleMobNavHandler = () => {
    setMobileNavActive(!mobileNavActive);
  };

  const pageCls =
    pathname.replace("/", "") === ""
      ? "home"
      : pathname.replace("/", "") + " no-home";

  useEffect(() => {
    if (
      window.__PRELOADED_STATE__?.theme?.theme == null &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      dispatch(switchToDark());
    }
    setCurrentPage(pathname.replace("/", "") + " no-heigtht");
  }, []);

  return (
    // <ErrorBoundary>
    <div className={`App ${pageCls}`}>
      <MobileNav
        handleMobNav={handleMobNavHandler}
        isActive={!mobileNavActive}
      />
      <aside>
        <nav
          className={
            !mobileNavActive && !isMobile()
              ? "mainNav desktopNav"
              : !mobileNavActive && isMobile()
              ? "mainNav mobileNav"
              : "mainNav mobileNavOpen"
          }
        >
          <Nav handleMobNav={handleMobNavHandler} />
          {/*<Menu />*/}
        </nav>
      </aside>
      <main className={"main " + currentPage} style={{ background: mainBG }}>
        {/*<Offline />*/}

        {content}
      </main>
    </div>
    // </ErrorBoundary>
  );
};
