import { FC, ReactElement } from "react";
import "../../components/Global/GlobalCss/hamburger.css";
import Hamburger from "hamburger-react";

interface IMobileNav {
  isActive: boolean;
  handleMobNav: () => void;
}

export const MobileNav: FC<IMobileNav> = ({
  isActive,
  handleMobNav,
}): ReactElement => {
  return (
    <div
      id="mobileHamburgerWrapper"
      style={{ position: "absolute", zIndex: "20", right: "20px", top: "20px" }}
    >
      <Hamburger toggled={isActive} toggle={handleMobNav} />
    </div>
  );
};
