import { FC, ReactElement } from "react";

import { PageMeta } from "components";
import useTranslations from "i18n/useTranslations";
import { ROUTE_CONSTANTS, siteUrl } from "constants/routeConstants";

const NotFound: FC = (): ReactElement => {
  const { t, tt } = useTranslations();

  return (
    <div className="main not-found">
      <PageMeta
        title={t.pageNames.pageNotFound}
        description={tt("notFoundSeoDesc")}
        canonicalUrl={siteUrl + ROUTE_CONSTANTS.NOT_FOUND}
      />
      <h1>{t.notFoundText}</h1>
    </div>
  );
};

export { NotFound };
