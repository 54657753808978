interface Item {
  title: string;
  img: string;
  url: string;
}

interface ResponsiveListProps {
  items: Item[];
}

const ResponsiveList: React.FC<ResponsiveListProps> = ({ items }) => {
  return (
    <div className="responsive-list">
      {items.map((item, index) => (
        <div key={index} className="list-item">
          <a href={item.url} className="item-link">
            <div className="item-image-wrapper">
              <img src={item.img} alt={item.title} className="item-image" />
            </div>
            <span className="item-title">{item.title}</span>
          </a>
        </div>
      ))}
    </div>
  );
};

export default ResponsiveList;
