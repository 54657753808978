import styled from "styled-components";
import classes from "./GlobalCss/infoPopUp.module.css";

const InfoPopUpStyle = styled.div`
  position: absolute;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border-radius: 0.25rem;
  bottom: 0;
  right: 0;
  width: inherit;
  text-align: center;
  z-index: 100;
  border-width: 1.5px;
`;

interface Iprops {
  infotype: string;
  content: string;
}
export const InfoPopUp = (props: Iprops) => {
  // const [showInfopopUpAnimation, setshowInfopopUpAnimation] = useState(true);

  let { infotype, content } = props;
  let dynamicClasses: string | string[] = [];
  if (infotype === "alertSuccess") {
    dynamicClasses = [classes.alertSuccess].join("");
  } else {
    dynamicClasses = [classes.alertError].join("");
  }

  return (
    <InfoPopUpStyle className={dynamicClasses} role="alert">
      {content}
    </InfoPopUpStyle>
  );
};
