import styled from "styled-components";
import { ReactNode } from "react";
import styles from "./skills.module.scss";

interface ISkillItem {
  id: number;
  title: string;
  content: string;
  children?: ReactNode;
}

export const SkillItem = (props: ISkillItem) => {
  const { id, title, content, children } = props;

  return (
    <SkillContainer>
      <Skill>
        <SkillIteration>{id}</SkillIteration>
        {title}
      </Skill>
      <div className={styles.skillContent}>{content}</div>
      {children}
    </SkillContainer>
  );
};

const SkillContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SkillIteration = styled.span`
  width: 50px;
  height: 50px;
  transform: skew(-10deg);
  position: absolute;
  background: white;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  left: -40px;
`;

const Skill = styled.div`
  position: relative;
  width: calc(100% - 55px);
  height: 40px;
  background-color: #037fff;
  display: flex;
  align-items: center;
  color: #fff;
  padding: 0 10px;
  transform: skew(-10deg);
  padding-left: 20px;
  margin-left: 50px;

  &:before {
    position: absolute;
    content: "";
    z-index: -1;
    bottom: 15px;
    left: 8px;
    top: 80%;
    width: 50%;
    background: #dedede;
    -webkit-box-shadow: 0 18px 15px #dedede;
    -moz-box-shadow: 0 18px 15px #dedede;
    box-shadow: 0 18px 15px #dedede;
    -webkit-transform: rotate(-4deg);
    -moz-transform: rotate(-4deg);
    -o-transform: rotate(-4deg);
    -ms-transform: rotate(-4deg);
    transform: rotate(-4deg);
  }
`;
