import { RouteObject } from "react-router-dom";

import { ROUTE_CONSTANTS } from "constants/routeConstants";
import { About, Contact, Home, NotFound, Skills, Sitemap } from "pages";
import { HireDev } from "pages/hire-dev/HireDev";
import { Nba } from "pages/nba/Nba";
import { Admin } from "pages/admin/Admin";
import { Projects } from "pages/projects/Projects";

const routes: RouteObject[] = [
  {
    path: "*",
    element: <NotFound />,
  },
  {
    path: ROUTE_CONSTANTS.HOME,
    element: <Home />,
  },
  // {
  //   path: ROUTE_CONSTANTS.FETCH,
  //   element: <Fetch />
  // },
  {
    path: ROUTE_CONSTANTS.ABOUT,
    element: <About />,
  },
  {
    path: ROUTE_CONSTANTS.SKILLS,
    element: <Skills />,
  },
  {
    path: ROUTE_CONSTANTS.CONTACT,
    element: <Contact />,
  },
  {
    path: ROUTE_CONSTANTS.SITEMAP,
    element: <Sitemap />,
  },
  {
    path: ROUTE_CONSTANTS.HIRE_DEV,
    element: <HireDev />,
  },
  {
    path: ROUTE_CONSTANTS.PROJECTS,
    element: <Projects />,
  },
  {
    path: ROUTE_CONSTANTS.NBA,
    element: <Nba />,
  },
  {
    path: ROUTE_CONSTANTS.ADMIN,
    element: <Admin />,
  },
  {
    path: ROUTE_CONSTANTS.NOT_FOUND,
    element: <NotFound />,
  },
  {
    path: "sw.js",
    loader: async () => {
      return await fetch("sw.js");
    },
  },
];
export { routes };
