import { FC, ReactElement } from "react";

import { PageMeta } from "components";
import useTranslations from "i18n/useTranslations";
import styles from "./projects.module.scss";
import HeaderGlobal from "components/HeaderGlobal";
import cn from "classnames";
import { ROUTE_CONSTANTS, siteUrl } from "constants/routeConstants";
import gorillaglueImg from "./images/gorillaglue-uk.png";
import okImg from "./images/ok.png";
import resultsImg from "./images/results.png";
import vekaImg from "./images/veka.png";
import tyxanImg from "./images/tyxan.png";
import britishsuperseriesImg from "./images/britishsuperseries.png";
import orbitImg from "./images/orbit.png";
import exportaImg from "./images/exporta.png";
import rezeeImg from "./images/rezee.png";
import metavateImg from "./images/metavate.png";
import lablifeImg from "./images/lablife.png";
import uniwipeImg from "./images/uniwipe.png";
import ResponsiveList from "pages/projects/ResponsiveList";

const items = [
  {
    title: "Tyxan",
    img: tyxanImg,
    url: "https://tyxan.com/",
  },
  {
    title: "Gorilla Glue UK",
    img: gorillaglueImg,
    url: "https://uk.gorillaglue.com/",
  },
  {
    title: "O'Keeffe's",
    img: okImg,
    url: "https://okeeffesco.co.uk/",
  },
  {
    title: "Results",
    img: resultsImg,
    url: "https://resultsagency.co.uk/",
  },
  {
    title: "Veka UK",
    img: vekaImg,
    url: "https://vekauk.com/",
  },
  {
    title: "British Thriatlon Superseries",
    img: britishsuperseriesImg,
    url: "https://britishsuperseries.com/",
  },
  {
    title: "Orbit",
    img: orbitImg,
    url: "https://commitments.orbit.org.uk/colleague-commitments/",
  },
  {
    title: "Exporta Global",
    img: exportaImg,
    url: "https://exportaglobal.co.uk/",
  },
  {
    title: "Rezee",
    img: rezeeImg,
    url: "https://rezee.co.uk/",
  },
  {
    title: "Metavate",
    img: metavateImg,
    url: "https://www.metavate.co.uk/",
  },
  {
    title: "Lablife",
    img: lablifeImg,
    url: "https://lablife.co.uk/",
  },
  {
    title: "Uniwipe",
    img: uniwipeImg,
    url: "https://uniwipe.com/",
  },
];

export const Projects: FC = (): ReactElement => {
  const { t, tt } = useTranslations();

  return (
    <div className={cn("mainProjects", styles.projects)}>
      <PageMeta
        title={t.pageNames.projects}
        description={tt("projectsSeoDesc")}
        canonicalUrl={siteUrl + ROUTE_CONSTANTS.PROJECTS}
      />

      <HeaderGlobal title="Projects" />

      <main style={{ marginTop: "40px" }}>
        <p style={{ marginBottom: "60px" }}>
          As part of the Tyxan team,
          <br /> I have played a key role in multiple projects.
        </p>
        <ResponsiveList items={items} />
      </main>
    </div>
  );
};
