import { NavLink } from "react-router-dom";
import classes from "./NavCSS/Nav.module.css";
import cn from "classnames";
import isMobile from "is-mobile";
import nbaLogo from "../../assets/images/png/nba-logo.png";
import { ROUTE_CONSTANTS } from "constants/routeConstants";
interface IProps {
  handleMobNav: () => void;
}

const NbaLink = () => {
  return (
    <>
      <img
        style={{ width: "90px", marginRight: "15px" }}
        src={nbaLogo}
        alt="nba logo"
      />
      fans
    </>
  );
};

const NavDetails = (props: IProps) => {
  const handleClick: React.MouseEventHandler<HTMLAnchorElement> = () => {
    if (isMobile()) {
      props.handleMobNav();
    } else {
      console.log("Not mobile");
    }
  };
  return (
    <ul className={classes.navUl}>
      <li>
        <NavLink
          to={ROUTE_CONSTANTS.HOME}
          onClick={handleClick}
          end
          className={({ isActive }) =>
            isActive ? cn("selected") : "not-selected"
          }
        >
          Home
        </NavLink>
      </li>
      <li>
        <NavLink
          className={({ isActive }) =>
            isActive ? cn("selected") : "not-selected"
          }
          to="/about"
          onClick={handleClick}
        >
          About
        </NavLink>
      </li>
      <li>
        <NavLink
          className={({ isActive }) =>
            isActive ? cn("selected") : "not-selected"
          }
          to="/skills"
          onClick={handleClick}
        >
          Skills
        </NavLink>
      </li>
      <li>
        <NavLink
          className={({ isActive }) =>
            isActive ? cn("selected") : "not-selected"
          }
          to="/contact"
          onClick={handleClick}
        >
          Contact
        </NavLink>
      </li>
      <li>
        <NavLink
          className={({ isActive }) =>
            isActive ? cn("selected") : "not-selected"
          }
          to="/projects"
          onClick={handleClick}
        >
          Projects
        </NavLink>
      </li>
      <li>
        <NavLink
          className={({ isActive }) =>
            isActive ? cn("selected") : "not-selected"
          }
          to="/nba-fans"
          onClick={handleClick}
        >
          <NbaLink />
        </NavLink>
      </li>
    </ul>
  );
};

export default NavDetails;
