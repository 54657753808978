import Button from "./CTA/Button";
import ctaStyle from "./CTA/cta.module.css";

interface IHalfWidthTextAndBtn {
  txtColor?: string;
  title: string;
  mainContent: string;
  btnURL: string;
  btnText: string;
  h2spacing?: boolean;
}

const HalfWidthTextAndBtn = (props: IHalfWidthTextAndBtn) => {
  return (
    <div className="w-45 mr-4 d-flex flex-column justify-content-center">
      <h2
        className={`${ctaStyle.aboutH2} ${
          props.h2spacing ? "mt-5 mt-lg-0" : ""
        }`}
      >
        <strong
          className="text-white"
          style={{ color: props.txtColor ? props.txtColor : "#191d2b" }}
        >
          {props.title}
        </strong>
      </h2>
      <p
        className="text-white"
        style={{ color: props.txtColor ? props.txtColor : "#191d2b" }}
      >
        {props.mainContent}
      </p>

      <a href={"/" + props.btnURL}>
        <Button ctaType={ctaStyle.borderedCTA} textVal={props.btnText} />
      </a>
    </div>
  );
};
export default HalfWidthTextAndBtn;
