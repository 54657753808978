import styled from "styled-components";

const HeaderGlobalLine = styled.div`
  width: 150px;
  height: 5px;
  background: rgba(3, 127, 255, 0.2);
  border-radius: 10px;
  margin-top: 0px;
  position: absolute;
`;

const HeaderGlobalLineInner = styled.div`
  width: 50px;
  height: 5px;
  background: #037fff;
  border-radius: 10px;
`;
interface IHeaderGlobal {
  title: string;
}

export const HeaderGlobal = (props: IHeaderGlobal) => {
  return (
    <div className="d-block p-relative">
      <h1>{props.title}</h1>
      <HeaderGlobalLine>
        <HeaderGlobalLineInner />
      </HeaderGlobalLine>
    </div>
  );
};
export default HeaderGlobal;
