import React, { useState } from "react";
import classes from "./contact.module.css";
import emailjs from "emailjs-com";

import { Formik } from "formik";
import Spiner from "components/Global/Spiner";

interface IProps {
  isFormSent: boolean;
  FormSendingCheck: (formSubmitionStatus: boolean) => void;
}

export const ContactUsForm = (props: IProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isFormSent, setIsFormSent] = useState(false);
  const [subittionType, setSubittionType] = useState(false);

  return (
    <>
      <div className={classes.spaciingXXL}> </div>
      <h2 className={classes.getintouch}>
        Get In <span className={classes.textBlue}>Touch</span>{" "}
      </h2>
      <h5 className={classes.my0}>Let's us know more about you. </h5>

      <Formik
        initialValues={{
          email: "",
          fullname: "",
          userPhone: "",
          message: "",
        }}
        validate={(values) => {
          const errors: any = {};
          if (!values.email) {
            errors.email = (
              <p style={{ color: "red", margin: "0 0 10px 0" }}>Required </p>
            );
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = (
              <p style={{ color: "red", margin: "0 0 10px 0" }}>
                Invalid email address{" "}
              </p>
            );
          }
          if (values.fullname.length < 2) {
            errors.fullname = (
              <p style={{ color: "red", margin: "0 0 10px 0" }}>Too Short </p>
            );
          } else if (values.fullname.length > 50) {
            errors.fullname = (
              <p style={{ color: "red", margin: "0 0 10px 0" }}>Too Long </p>
            );
          }
          if (values.userPhone.length > 15) {
            errors.userPhone = (
              <p style={{ color: "red", margin: "0 0 10px 0" }}>Too Long </p>
            );
          }
          if (values.message.length > 200) {
            errors.message = (
              <p style={{ color: "red", margin: "0 0 10px 0" }}>
                Message too long max 200 characters.{" "}
              </p>
            );
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          setIsLoading(true);

          setTimeout(() => {
            // alert(JSON.stringify(values, null, 2));
            setSubmitting(false);
            let templateParams = {
              user_name: values.fullname,
              fullName: values.fullname,
              email: values.email,
              phone: values.userPhone,
              message: values.message,
              reply_to: values.email,
              from_name: values.fullname,
              to_name: values.fullname,
              message_html: values.userPhone + " " + values.message,
            };
            emailjs
              .send(
                "service_n9ewwhb",
                "template_3MhQthmR",
                templateParams,
                "user_ku4M2DC1HS6WKSp1RaCHk"
              )
              .then(
                (response) => {
                  //console.log('SUCCESS!', response.status, response.text);
                  setIsLoading(false);
                  setIsFormSent(true);
                  setSubittionType(true);
                  // this.setState({
                  //   fullName: "",
                  //   email: "",
                  //   phone: "",
                  //   message: "",
                  // });
                  props.FormSendingCheck(subittionType);
                },
                (err) => {
                  // console.log('FAILED...', err);
                  setIsLoading(false);
                  setIsFormSent(false);
                  setSubittionType(false);

                  props.FormSendingCheck(subittionType);
                }
              );
          }, 400);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          isValidating,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit} className={classes.formStyles}>
            <input
              type="text"
              name="fullname"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.fullname}
              placeholder="Full Name"
              className={classes.inputStyle}
            />
            {errors.fullname && touched.fullname && errors.fullname}

            <input
              type="email"
              name="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              placeholder="Email address"
              className={classes.inputStyle}
            />
            {errors.email && touched.email && errors.email}

            <input
              type="phone"
              name="userPhone"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.userPhone}
              placeholder="Phone"
              className={classes.inputStyle}
            />
            {errors.userPhone && touched.userPhone && errors.userPhone}

            <textarea
              style={{ height: 100 }}
              name="message"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.message}
              placeholder="Message"
              className={classes.inputStyle}
            />
            {isLoading && <Spiner />}
            <input
              type="submit"
              className={classes.borderedSubmit}
              value="Send"
            />
            {errors.message && touched.message && errors.message}
          </form>
        )}
      </Formik>
    </>
  );
};
